import BaseDexie from 'dexie'

export default class Dexie extends BaseDexie {
  constructor () {
    super('webzabe')

    this.version(1).stores({
      downloadedVideos: 'videoId, createdAt',
      downloadingVideos: 'videoId, createdAt',
      cacheMetadata: 'key'
    })
  }
}
