import firebase from './firebase'
import 'firebase/firestore'

export const db = firebase.firestore()

db.enablePersistence({ synchronizeTabs: true }).then(() => {
  console.log('Woohoo! Multi-Tab Persistence!')
})

const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }
