<template>
  <div data-vuetify>
    <v-app>
      <v-alert
        type="info"
        v-if="isDownloading && isShow"
        v-bind:style="{ zIndex: zIndex }"
      >
        ダウンロードを開始しました
      </v-alert>
      <v-alert
        type="warning"
        icon="mdi-alert"
        v-if="isAlreadyDownloading && isShow"
        v-bind:style="{ zIndex: zIndex }"
      >
        すでにダウンロード中です
      </v-alert>
      <v-alert
        type="success"
        v-if="isDownloadSuccess && isShow"
        v-bind:style="{ zIndex: zIndex }"
      >
        ダウンロードが成功しました
      </v-alert>
      <v-alert
        type="error"
        v-if="isDownloadFailed && isShow"
        v-bind:style="{ zIndex: zIndex }"
      >
        <span v-if="error === null">ダウンロードが失敗しました</span>
        <span v-else>{{ error }}</span>
      </v-alert>
      <v-alert
        type="warning"
        icon="mdi-alert"
        v-if="isAlreadyDownloaded && isShow"
        v-bind:style="{ zIndex: zIndex }"
      >
        すでにダウンロード済みです
      </v-alert>
    </v-app>
  </div>
</template>

<script>
export default {
  props: {
    status: String,
    error: { type: String, default: null }
  },
  data () {
    return {
      isShow: true,
      zIndex: 999
    }
  },
  computed: {
    isDownloading () {
      return this.status === 'downloading'
    },
    isAlreadyDownloading () {
      return this.status === 'already_downloading'
    },
    isDownloadSuccess () {
      return this.status === 'success'
    },
    isDownloadFailed () {
      return this.status === 'failed'
    },
    isAlreadyDownloaded () {
      return this.status === 'already_downloaded'
    }
  },
  mounted () {
    setTimeout(() => {
      this.isShow = false
    }, 5000)
  },
  created () {
    this.zIndex = this.$downloadAlertZIndex
    this.$downloadAlertZIndex += 1
  }
}
</script>

<style scoped>
.v-alert {
  position: fixed;
  width: 100%;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  overflow-wrap: break-word;
  word-break: keep-all;
  z-index: 999;
}
</style>
