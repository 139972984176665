import Vue from 'vue'
import VueRouter from 'vue-router'

// NOTE: 現在と同じルートへの遷移時に出るエラーを無視する
const { push } = VueRouter.prototype
const { isNavigationFailure, NavigationFailureType } = VueRouter
VueRouter.prototype.push = function (location) {
  return push.call(this, location).catch((error) => {
    if (!isNavigationFailure(error, NavigationFailureType.duplicated)) {
      throw error
    }
  })
}

Vue.use(VueRouter)

const ExplorePage = () => import('./pages/ExplorePage.vue')
const PostDialog = () => import('./components/PostDialog.vue')
const NotFoundPage = () => import('./NotFoundPage.vue')
// const DownloadedPage = () => import('./pages/DownloadedPage.vue')
// const DownloadedPostDialog = () => import('./components/DownloadedPostDialog.vue')
const AboutPage = () => import('./pages/AboutPage.vue')
const TermsPage = () => import('./pages/TermsPage.vue')
const PrivacyPage = () => import('./pages/PrivacyPage.vue')
const ContactPage = () => import('./pages/ContactPage.vue')

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'top',
      components: {
        page: ExplorePage
      },
      meta: { canonical: 'https://webzabe.rocks/' }
    },
    {
      path: '/explore',
      name: 'explore',
      components: {
        page: ExplorePage
      },
      meta: {
        canonical: (route) => {
          return route.query.name && route.query.grade && route.query.area
            ? `https://webzabe.rocks/explore?name=${route.query.name}&grade=${route.query.grade}&area=${route.query.area}`
            : 'https://webzabe.rocks/'
        }
      }
    },
    {
      path: '/explore/p/:videoId',
      name: 'post',
      components: {
        page: ExplorePage,
        dialog: PostDialog
      },
      props: {
        page: false,
        dialog: true
      },
      meta: {
        canonical: (route) => {
          return `https://webzabe.rocks/explore/p/${route.params.videoId}`
        }
      }
    },
    // {
    //   path: '/downloaded',
    //   name: 'downloaded',
    //   components: {
    //     page: DownloadedPage
    //   }
    // },
    // {
    //   path: '/downloaded/p/:videoId',
    //   name: 'downloaded-post',
    //   components: {
    //     page: DownloadedPage,
    //     dialog: DownloadedPostDialog
    //   },
    //   props: {
    //     page: false,
    //     dialog: true
    //   }
    // },
    {
      path: '/about',
      name: 'about',
      components: {
        page: AboutPage
      },
      meta: { canonical: 'https://webzabe.rocks/about' }
    },
    {
      path: '/terms',
      name: 'terms',
      components: {
        page: TermsPage
      },
      meta: { canonical: 'https://webzabe.rocks/terms' }
    },
    {
      path: '/privacy',
      name: 'privacy',
      components: {
        page: PrivacyPage
      },
      meta: { canonical: 'https://webzabe.rocks/privacy' }
    },
    {
      path: '/contact',
      name: 'contact',
      components: {
        page: ContactPage
      },
      meta: { canonical: 'https://webzabe.rocks/contact' }
    },
    {
      path: '*',
      name: '404',
      components: {
        page: NotFoundPage
      },
      meta: { noindex: true }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.params.savedPosition) {
      return to.params.savedPosition
    }
    document.getElementById('app').scroll(0, 0)
    return { x: 0, y: 0 }
  }
})
