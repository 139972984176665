<template>
  <vue100vh :css="{height: '100rvh'}">
    <div id="app" v-if="isNotFound">
      <AppBanner></AppBanner>
      <NotFoundPage />
    </div>
    <div id="app" v-else>
      <AppBanner></AppBanner>
      <div id="container" v-show="!isMobile || (isMobile && !isShowDialog)">
        <router-view name="page"/>
      </div>
      <div id="dialog-container" class="fade" v-if="isShowDialog">
        <div id="cover" @click="closeDialog" v-if="!isMobile"></div>
        <router-view name="dialog" ref="dialog"></router-view>
      </div>
    </div>
    <DownloadAlert v-for="(downloadingVideo, i) in $downloadingVideos" :status="'downloading'" :key="`downloadingVideo-${i}`"></DownloadAlert>
    <DownloadAlert v-for="(downloadSuccessVideo, i) in $downloadSuccessVideos" :status="'success'" :key="`downloadSuccessVideo-${i}`"></DownloadAlert>
    <DownloadAlert v-for="(downloadFailedVideo, i) in $downloadFailedVideos" :status="'failed'" :error="downloadFailedVideo.error" :key="`downloadFailedVideo-${i}`"></DownloadAlert>
    <DownloadAlert v-for="(alreadyDownloadingVideo, i) in $alreadyDownloadingVideos" :status="'already_downloading'" :key="`alreadyDownloadingVideo-${i}`"></DownloadAlert>
    <DownloadAlert v-for="(alreadyDownloadedVideo, i) in $alreadyDownloadedVideos" :status="'already_downloaded'" :key="`alreadyDownloadedVideo-${i}`"></DownloadAlert>
    <TheFooter ref="footer"></TheFooter>
  </vue100vh>
</template>

<script>
import vue100vh from 'vue-100vh'
import NotFoundPage from './NotFoundPage'
import TheFooter from './components/TheFooter'
import DownloadAlert from './components/DownloadAlert'
import AppBanner from './components/AppBanner'
import dao from './dao'

export default {
  name: 'app',
  components: { vue100vh, NotFoundPage, TheFooter, DownloadAlert, AppBanner },
  data () {
    return {
      windowWidth: window.innerWidth,
      isNotFound: false
    }
  },
  computed: {
    isMobile () {
      return this.windowWidth <= 480
    },
    isShowDialog () {
      return this.$route.name === 'post' ||
        this.$route.name === 'downloaded-post'
    }
  },
  methods: {
    closeDialog () {
      console.log('closeDialog')
      const to = this.$route.name === 'downloaded-post' ? 'downloaded' : 'explore'
      this.$router.push({ name: to, query: this.$refs.dialog.dataPageQuery })
    },
    updateData (videos) {
      console.log('updateData')
      console.log(videos)
      this.$refs.dialog.dataVideos = videos
    },
    showNotFound () {
      this.isNotFound = true
    },
    downloadStarted (video) {
      this.$downloadingVideos.push(video)
      this.$refs.footer.downloadingVideos.push(video)
    },
    downloadFinished (video) {
      this.$downloadSuccessVideos.push(video)
      this.$refs.footer.downloadingVideos = this.$refs.footer.downloadingVideos.filter(v => {
        return video.videoId !== v.videoId
      })
      this.$downloadingVideos = this.$downloadingVideos.filter(v => {
        return video.videoId !== v.videoId
      })
    },
    downloadFailed (video, error = null) {
      this.$downloadFailedVideos.push({ video, error })
      this.$refs.footer.downloadingVideos = this.$refs.footer.downloadingVideos.filter(v => {
        return video.videoId !== v.videoId
      })
      this.$downloadingVideos = this.$downloadingVideos.filter(v => {
        return video.videoId !== v.videoId
      })
    }
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    this.$router.app.$off('data_updated_2')
    this.$router.app.$on('data_updated_2', this.updateData)
    this.$router.app.$off('404')
    this.$router.app.$on('404', this.showNotFound)
    this.$router.app.$off('download_started')
    this.$router.app.$on('download_started', this.downloadStarted)
    this.$router.app.$off('download_finished')
    this.$router.app.$on('download_finished', this.downloadFinished)
    this.$router.app.$off('download_failed')
    this.$router.app.$on('download_failed', this.downloadFailed)
  },
  created () {
    setInterval(() => {
      caches.open('webzabe-downloaded-videos-v1')
        .then((cache) => {
          cache.keys()
            .then((keys) => {
              keys.forEach((request, index, array) => {
                const url = new URL(request.url)
                const params = new URLSearchParams(url.search)
                const videoId = params.get('videoId')
                cache.match(request)
                  .then(response => {
                    if (!response.ok) {
                      throw response
                    }
                    return response.blob()
                  })
                  .then(blob => {
                    dao.findVideoByVideoId(videoId).get().then((querySnapshot) => {
                      const docs = querySnapshot.docs
                      if (docs.length === 0) {
                        return
                      }
                      const videos = []
                      docs.map(doc => videos.push(doc.data()))
                      const problems = videos.map(v => {
                        return {
                          name: v.problemName,
                          grade: v.problemGrade,
                          area: v.problemArea
                        }
                      })

                      dao.saveDownloadedVideo({
                        videoId,
                        data: blob,
                        problems
                      })
                      console.log(`download finished - ${videoId}`)
                      dao.deleteDownloadingVideo(videoId)
                      this.$router.app.$emit('download_finished', videos[0])
                    })
                      .catch(error => {
                        throw error
                      })
                  })
                  .catch(error => {
                    let message = '動画のダウンロードに失敗しました'
                    if (error.status && error.status === 507) {
                      message = 'この動画はダウンロードできません'
                    }
                    console.log(`download failed - ${videoId}`)
                    dao.deleteDownloadingVideo(videoId)
                    this.$router.app.$emit('download_failed', { videoId }, message)
                    console.log(error)
                    throw new Error(error)
                  })
                cache.delete(request)
              })
            })
        })
    }, 2000)
  }
}
</script>

<style>
:not([data-vuetify]) {
  box-sizing: content-box;
}
[data-vuetify] {
  box-sizing: border-box;
}
[data-vuetify] *, ::before, ::after {
  box-sizing: inherit;
}

[data-vuetify] #app {
  height: unset;
}
[data-vuetify] .v-application--wrap {
  min-height: unset;
}
</style>

<style scoped>

#cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: black;
  opacity: 0.5;

  z-index: 998;
}

#dialog-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content:center;
  flex-direction: column;

  z-index: 2;
}
</style>

<style>
  @import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

  * {
    margin: 0;
    padding: 0;
  }

  html {
    padding: env(safe-area-inset);
  }

  body {
    background-color: white;
  }

  input:focus, textarea:focus {
      outline: 0;
  }

  #app {
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10pt;

    /* retain dialog position */
    height: 100%;
    overflow-y: auto;
  }

  #container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;

    /*
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    */

    height: calc(100% - 1em);
  }

   header {
     padding: 0 10px 0 10px;
   }

  .noselect {
    -webkit-touch-callout: none;
    user-select: none;
  }

  .profile {
    display: flex;
    align-items: center;
  }

  .profile > .name {
    padding: 0 0.6em 0 0.6em;
  }

  .profile > .follow {
    padding: 0 0.6em 0 0;
  }

  .profile > .follow > a {
    font-size: 0.8em;
    text-decoration: none;
  }

  .icon-normal {
    width: 45px;
    height: 45px;
  }

  .icon-small {
    width: 30px;
    height: 30px;
  }

  .icon {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;

    border: 1px solid #fff;
    box-shadow: 0 0 0 1px #aaa;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .fade, .fade-img[lazy=loaded] {
      opacity: 0;
      animation-name: fadein;
      animation-duration: .2s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-direction: normal;
      animation-timing-function: ease-out;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px
*/
@media (min-width: 768px) {
  html {
    overflow-y: hidden !important;
  }

  .grid > div.item {
    flex-basis: calc(33.333% - 20px);
  }

  .grid > div.item:nth-child(odd) {
      /*background-color: #eeeeee;*/
      background-color: black;
  }

  .grid > div.item:nth-child(even) {
      /*background-color: #eeeeee;*/
      background-color: black;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
  html {
    overflow-y: hidden !important;
  }

  .grid > div.item {
    flex-basis: calc(50% - 20px);
  }

  .grid > div.item:nth-child(2n+1) {
      /*background-color: #eeeeee;*/
      background-color: black;
  }

  .grid > div.item:nth-child(2n+2) {
      /*background-color: #eeeeee;*/
      background-color: black;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (max-width: 480px) {

  #app {
    overflow-y: initial;
  }

  .grid > div.item {
    flex-basis: calc(100% - 20px);
  }

  .grid > div.item:nth-child(odd) {
      /*background-color: #eeeeee;*/
      background-color: black;
  }

  .grid > div.item:nth-child(even) {
      /*background-color: #eeeeee;*/
      background-color: black;
  }
}
</style>
