import { db } from './db'
import Dexie from './dexie'

const indexedDb = new Dexie()

const dao = {
  findAllProblems: () => {
    return db.collection('problems').orderBy('name') // TODO: 複数のフィールドでorderByするにはインデックスを張る必要がある
  },

  findAllVideosByProblem: (problemName, problemGrade, problemArea, lastVisible = null, perPage = 30) => {
    console.log(problemName)
    console.log(problemGrade)
    console.log(problemArea)
    console.log(lastVisible)
    console.log(perPage)
    let videos = db.collection('videos')
      .where('problemName', '==', problemName)
      .where('problemGrade', '==', problemGrade)
      .where('problemArea', '==', problemArea)
      .orderBy('publishedAt', 'desc')
      .limit(perPage)
    if (lastVisible !== null) {
      videos = videos.startAfter(lastVisible)
    }
    return videos
  },

  findVideoByVideoId: (videoId) => {
    console.log(videoId)
    const video = db.collection('videos')
      .where('videoId', '==', videoId)
    return video
  },

  downloadVideo: (videoId) => {
    console.log(videoId)
    const params = { videoId }
    const query = new URLSearchParams(params)
    return fetch(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/downloadVideo?${query}`)
  },

  findAllDownloadedVideos: (page = 1, perPage = 30) => {
    const offset = (page - 1) * perPage
    console.log(offset)
    return indexedDb.downloadedVideos.orderBy('createdAt').reverse().offset(offset).limit(perPage).toArray()
  },

  // TODO: 課題名とグレードとエリアも渡すようにしてfindDownloadedVideoByProblemAndVideoIdを使うようにしたい
  findDownloadedVideoByVideoId: (videoId) => {
    console.log(videoId)
    return indexedDb.downloadedVideos.where('videoId').equals(videoId).first()
  },

  findDownloadedVideoByProblemAndVideoId: (problemName, problemGrade, problemArea, videoId) => {
    console.log(problemName)
    console.log(problemGrade)
    console.log(problemArea)
    console.log(videoId)
    return indexedDb.downloadedVideos
      .where({
        problemName,
        problemGrade,
        problemArea,
        videoId
      }).first()
  },

  findProblemsVersion: async () => {
    return await db.collection('problemsVersion').doc('problemsVersion').get().then(doc => doc.data().problemsVersion)
  },

  findCacheMetadataByKey: (key) => {
    return indexedDb.cacheMetadata.where('key').equals(key).first()
  },

  saveCacheMetadata: (metadata) => {
    indexedDb.cacheMetadata.put(metadata)
  },

  saveDownloadedVideo: (video) => {
    const now = new Date()
    const oneDaysAgo = new Date(now.getTime() + (24 * 60 * 60 * 1000))
    indexedDb.downloadedVideos.add({
      videoId: video.videoId,
      data: video.data,
      problems: video.problems,
      expiredAt: oneDaysAgo,
      createdAt: now
    })
  },

  deleteDownloadedVideo: (video) => {
    indexedDb.downloadedVideos
      .where({
        videoId: video.videoId
      }).delete()
  },

  findDownloadingVideoByVideoId: (videoId) => {
    console.log(videoId)
    return indexedDb.downloadingVideos.where('videoId').equals(videoId).first()
  },

  findAllDownloadingVideos: () => {
    return indexedDb.downloadingVideos.orderBy('createdAt').reverse().toArray()
  },

  saveDownloadingVideo: (videoId) => {
    const now = new Date()
    indexedDb.downloadingVideos.add({
      videoId,
      createdAt: now
    })
  },

  deleteDownloadingVideo: (videoId) => {
    indexedDb.downloadingVideos
      .where({
        videoId
      }).delete()
  }
}

export default dao
